<template>
  <div class="transaction-panel">
    <div class="layout-left d-flex align-items-start flex-column">
      <div class="detail-info mb-auto">
        <p class="data-info">ข้อมูลลูกค้า</p>
        <p class="title-info">หมายเลขสมาชิก (Member No.)</p>
        <p class="data-info">{{ user.member_id }}</p>
        <p class="title-info">ชื่อลูกค้า (Customer Name)</p>
        <p class="data-info">
          {{ user.first_name_th }} {{ user.last_name_th }} ({{
            user.member_level_name
          }})
        </p>
        <p class="title-info">โทรศัพท์ (Telephone)</p>
        <p class="data-info">{{ user.telephone }}</p>
      </div>
      <div class="text-center w-100 pb-3 action-desktop">
        <b-button variant="primary-color" to="/transaction-error">
          ย้อนกลับ
        </b-button>
      </div>
    </div>
    <div class="layout-right">
      <div v-for="(transaction, i) in transactions" :key="i" class="px-3">
        <div class="transaction-status">
          <div>
            <p class="text-ref-no">
              POS REF NO: {{ transaction.invoice_no }} ({{
                transaction.ref_invoice_no
                  ? "Return Ref No : " + transaction.ref_invoice_no
                  : ""
              }})
              <router-link
                v-if="transaction.point_exchange_url"
                :to="transaction.point_exchange_url"
                target="_blank"
                class="ml-1"
              >
                <img
                  src="@/assets/images/icons/download-icon.jpg"
                  class="download-url pointer"
                  height="28"
                />
              </router-link>
            </p>
            <p class="text-time">
              {{
                $moment(transaction.created_time)
                  .add(543, "years")
                  .format($formatDateNewFull)
              }}
              Salesperson : {{ transaction.sales_no }}
              {{ transaction.sales_name }}
            </p>
          </div>
          <div class="detail">
            <p class="text-status">สาขา {{ transaction.branch_name }}</p>
            <p :class="['text-status text-danger']">
              ปัญหา: {{ transaction.reason }}
            </p>
          </div>
        </div>
        <b-row no-gutters class="head-total h-auto">
          <b-col>
            <div class="bg-secondary">
              <p>Total QTY</p>
              <p>(จำนวนสุทธิ)</p>
              <p>{{ transaction.quantity | numeral("0,0") }}</p>
            </div>
          </b-col>
          <b-col>
            <div class="bg-secondary">
              <p>Total Amount</p>
              <p>(ราคารวม)</p>
              <p>{{ transaction.price | numeral("0,0.00") }}</p>
            </div>
          </b-col>
          <b-col>
            <div class="bg-secondary">
              <p>Total Discount</p>
              <p>(ส่วนลดรวม)</p>
              <p>{{ transaction.discount | numeral("0,0.00") }}</p>
            </div>
          </b-col>
          <b-col>
            <div class="bg-secondary">
              <p>Total Net Amount</p>
              <p>(ราคาสุทธิ)</p>
              <p>{{ transaction.grand_total | numeral("0,0.00") }}</p>
            </div>
          </b-col>
          <b-col v-if="$store.state.isCenterPoint == 0">
            <div class="bg-secondary">
              <p>Branch Point</p>
              <p>(คะแนนสะสมสาขา)</p>
              <p>{{ transaction.point | numeral("0,0") }}</p>
            </div>
          </b-col>
          <b-col>
            <div class="bg-secondary">
              <p>Total Point</p>
              <p>(คะแนนสะสม)</p>
              <p>{{ transaction.center_point | numeral("0,0") }}</p>
            </div>
          </b-col>
        </b-row>
        <template v-if="!isMobile">
          <div class="mx-3 mb-3">
            <b-row class="mx-0 text-center header-products">
              <b-col cols="1">
                <p>จำนวน</p>
                <p>Qty</p>
              </b-col>
              <b-col md="6">
                <p>รายละเอียดสินค้า/โปรโมชั่น</p>
                <p>Description/Promotion</p>
              </b-col>
              <b-col>
                <p>ราคาต่อหน่วย</p>
                <p>Unit Price</p>
              </b-col>
              <b-col>
                <p>ส่วนลด</p>
                <p>Discount</p>
              </b-col>
              <b-col>
                <p>ราคาสุทธิ</p>
                <p>Net</p>
              </b-col>
            </b-row>
          </div>
          <div class="product-list-container">
            <div class="item-products" v-if="transaction.product.length > 0">
              <b-row
                v-for="(product, index) in transaction.product"
                :key="index"
                class="product-panel mx-0 mb-2 h-100"
              >
                <b-col
                  cols="1"
                  class="border-product d-flex justify-content-center align-items-center"
                >
                  <p class="my-0">{{ product.quantity }}</p>
                </b-col>
                <b-col md="6" class="pointer py-1">
                  <p>{{ product.barcode }}</p>
                  <p class="product-info">{{ product.name }}</p>
                  <p v-if="product.promotion">
                    <span>
                      โปรโมชั่น:
                      {{
                        !product.promotion || product.promotion.length == 0
                          ? "-"
                          : ""
                      }}</span
                    >
                    <span
                      v-for="(promotion, index2) in product.promotion"
                      :key="index2"
                    >
                      {{ promotion.name }} ลด {{ promotion.discount_value }},
                    </span>
                  </p>
                </b-col>
                <b-col
                  class="price d-flex justify-content-center align-items-center"
                >
                  <div>
                    <p>
                      {{
                        (product.sap_price || product.price) | numeral("0,0.00")
                      }}
                    </p>
                    <p
                      v-if="product.sap_price != product.price"
                      class="normal-unit-price"
                    >
                      {{ product.price | numeral("0,0.00") }}
                    </p>
                  </div>
                </b-col>
                <b-col
                  class="price d-flex justify-content-center align-items-center"
                >
                  <p>
                    {{ product.total_discount | numeral("0,0.00") }}
                  </p>
                </b-col>
                <b-col
                  class="price d-flex justify-content-center align-items-center"
                >
                  <p>
                    {{ product.after_discount_price | numeral("0,0.00") }}
                  </p>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="transaction.product.length > 0">
            <b-row
              class="mx-0 my-1 p-2 bg-white product-panel"
              v-for="(product, index) in transaction.product"
              :key="index"
            >
              <b-col
                cols="2"
                class="text-center pl-0 pr-0 border-product d-flex align-items-center"
              >
                <p>{{ product.quantity }}</p>
              </b-col>

              <b-col class="px-2 pointer text-general">
                <p>{{ product.barcode }}</p>
                <p>{{ product.name }}</p>
                <p>
                  โปรโมชั่น:
                  {{
                    product.promotion_detail
                      ? product.promotion_detail.textPromotion
                      : "-"
                  }}
                </p>
              </b-col>

              <b-col class="pr-0 pointer text-right"
                ><div>
                  <p>
                    {{ product.sap_price | numeral("0,0.00") }}
                  </p>
                  <p
                    v-if="product.sap_price != product.price"
                    class="normal-unit-price"
                  >
                    {{ product.price | numeral("0,0.00") }}
                  </p>
                </div></b-col
              >
            </b-row>
          </div>
        </template>
        <div class="w-100 my-2">
          <b-button variant="primary-color" @click="retryBill(transaction)">
            แก้ไขข้อมูลบิลแล้วลองใหม่อีกครั้ง
          </b-button>
        </div>
        <hr class="" />
      </div>
    </div>
    <div class="text-center w-100 pb-3 action-mobile">
      <b-button variant="primary-color" to="/transaction-error">
        ย้อนกลับ
      </b-button>
    </div>
    <!-- <ModalDeletelTransaction
      ref="modalDeletelTransaction"
      :branchId="branchId"
      @success="(id) => getTransactionDetail(id)"
    /> -->
    <ModalAlertConfirm
      ref="modalAlertConfirm"
      :msg="alertMsg"
      :text="alertText"
      @confirm="confirmReBill"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import ModalDeletelTransaction from "./components/ModalDeletelTransaction";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";

export default {
  components: {
    ModalAlertConfirm,
    // ModalDeletelTransaction,
  },
  computed: {
    ...mapGetters(["isLoading"]),
    ...mapGetters({
      mainLoading: "getMainLoading",
      branchId: "getBranchId",
      pointSystem: "getPointSystem",
    }),
  },
  data() {
    return {
      isMobile: false,
      user: {
        center_point: 0,
        created_time: "",
        credit: 0,
        first_name_th: "",
        last_name_th: "",
        member_id: "",
        member_level_id: 0,
        member_level_name: "",
        point: 0,
        telephone: "",
        user_guid: "",
        user_id: 0,
        valid_user: 1,
        birthday: "",
        consent_status: "",
        sumPoint: 0,
      },
      params: {
        userGuid: "",
        transactionId: "",
      },
      form: {
        transactionId: 0,
      },
      transactions: [],
      filter: {
        page: 1,
      },
      totalPage: 1,
      alertMsg: "",
      alertText: "",
      selectedTransactionId: 0,
    };
  },
  async created() {
    this.params.userGuid = this.$route.params.userGuid || "";
    this.params.transactionId = this.$route.params.id;
    this.form.transactionId = this.params.transactionId;
    if (!this.params.transactionId)
      this.$router.push({ path: `/transaction-error` }).catch(() => {});

    await this.getUserDetail(this.params.userGuid);
    await this.getTransactionDetail(this.form.transactionId);
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
    async getUserDetail(userGuid) {
      await this.axios
        .get(
          `${this.$baseUrl}/customer/user_lookup_byid/${userGuid}/${this.branchId}`
        )
        .then(async (data) => {
          if (data.result == 1) {
            this.user = data.detail;
            if (this.user.birthday == "01/01/0001 00:00:00")
              this.user.birthday = null;
            else
              this.user.birthday = this.$moment(
                this.user.birthday,
                "MM/DD/YYYY HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss");
            this.user.sumPoint = data.detail.center_point + data.detail.point;
          }
        });
    },
    async getTransactionDetail(transactionId) {
      await this.axios
        .get(
          `${this.$baseUrl}/transaction/get_transaction_error_detail/${transactionId}/${this.params.userGuid}/1/${this.branchId}`
        )
        .then(async (data) => {
          if (data.result == 1) {
            this.totalPage = data.total_page;
            this.transactions = data.detail;
            await this.transactions.forEach((transaction) => {
              transaction.quantity = transaction.product.reduce(
                (a, b) => a + (b.quantity || 0),
                0
              );

              transaction.price =
                transaction.discount + transaction.grand_total;
            });
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    // async deleteTransaction(transaction) {
    //   this.$refs.modalDeletelTransaction.show(transaction);
    // },
    async retryBill(transaction) {
      this.selectedTransactionId = transaction.id;
      this.alertMsg = `แก้ไขบิล ${transaction.invoice_no}`;
      this.alertText = `POS REF NO ${transaction.invoice_no} 
              ${
                transaction.ref_invoice_no
                  ? "(Return Ref No : " + transaction.ref_invoice_no + ")"
                  : ""
              }
              ยืนยันที่จะแก้ไขบิลหรือไม่`;
      this.$refs.modalAlertConfirm.show();
    },
    async confirmReBill() {
      this.isLoading = true;
      let body = {
        id: this.selectedTransactionId,
      };
      await this.$store.dispatch("setMainLoading", true);
      await this.axios
        .post(`${this.$baseUrl}/transactionerror/retry`, body)
        .then((data) => {
          this.isLoading = false;
          this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert(data.message);
            this.$router.push({ path: `/transaction-error` }).catch(() => {});
          } else {
            this.warningAlert(data.message);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .layout-left {
  width: 300px;
  background: #f7f7f7;
  height: calc(100vh - 46px);
  position: relative;
  font-family: Kanit-Medium !important;
  .detail-info {
    padding: 20px;
    .title-info {
      font-size: 14px;
      color: grey;
    }
    .data-info {
      color: #333;
      font-size: 16px;
      text-align: left;
      margin-bottom: 15px;
    }
  }
}
::v-deep .layout-right {
  width: calc(100% - 300px);
  height: calc(100vh - 46px);
  overflow: auto;
  .text-ref-no {
    font-family: Kanit-Medium !important;
    font-size: 20px;
  }
  .text-time,
  .text-saleId {
    font-family: Kanit-Medium !important;
    font-size: 14px;
  }
  .text-status {
    font-family: Kanit-Medium !important;
    font-size: 18px;
  }
  .text-status.text-success {
    color: #3c763d !important;
  }

  .head-total {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    p {
      text-align: center;
      color: var(--primary-color) !important;
      font-size: 14px;
      font-weight: 600;
    }

    .col {
      padding: 0;
      min-width: 133px;
      flex: 1;
      max-width: 100%;
    }

    .bg-secondary {
      height: 100%;
      padding: 15px 0;
      background: var(--secondary-color) !important;
    }
  }
  .product-list-container {
    overflow: auto;
    height: calc(100% - 46px - 89px - 87px - 40px);
    position: relative;
    .wrap-no-product {
      width: 100%;
      height: 100%;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
      .no-product {
        text-align: center;
        font-size: 20px;
        padding: 15px;
        background-color: #f7f7f7;
        border-radius: 10px;
        color: #dcdcdc;
        width: 450px;
      }
    }
  }
  .header-products {
    font-size: 14px;
    font-weight: bold;
  }
  .product-panel {
    background-color: rgb(244, 244, 244) !important;
  }
  .product-panel.active {
    background-color: var(--secondary-color) !important;
  }
  .item-products {
    color: #707070;
    font-size: 16px;
    border-radius: 8px;
    font-family: Kanit-Medium !important;
    svg {
      color: #000;
    }
    .price {
      font-size: 14px;
      text-align: center;
    }
    .product-info {
      font-size: 12px !important;
    }
    span {
      font-size: 12px !important;
      color: #707070 !important;
    }
    .normal-unit-price {
      text-decoration: line-through;
    }
  }
}
::v-deep .btn-link {
  border-color: transparent !important;
  background-color: transparent !important;
  text-decoration: underline !important;
  padding: 5px 0;
  color: var(--primary-color) !important;
}

.transaction-panel {
  display: flex;
}

.action-desktop {
  display: block;
}

.action-mobile {
  display: none;
}

@media (max-width: 768px) {
  .transaction-panel {
    display: block;

    .layout-left {
      width: 100%;
      height: 100%;

      .detail-info {
        width: 100%;
      }
    }

    .layout-right {
      width: 100%;
      height: 100%;

      .transaction-status {
        display: block;
        padding: 12px 0;

        .detail {
          text-align: left;
        }
      }
    }

    .action-desktop {
      display: none;
    }

    .action-mobile {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      margin-bottom: 16px;
      width: 100%;
    }
  }
}
</style>
